.about-body {
    margin: 0px 300px 0 300px
}

.about-body li, .about-body h3 {
    text-align: left;
    margin: 0px 150px 0px 150px
}

.about-body img {
    height: 250px;
    border-color: black;
    border-radius: 10px;
    border-style: solid;
    margin-top: 10px
}
