.projects-card {
    border: 3px;
    border-color: black;
    border-radius: 10px;
    border-style: solid;
    justify-content: center;
    width: 30%;
    margin: 0 auto;
    margin-top: 10px;
    background-color: rgb(3, 152, 3);
}

.projects-card h3, a {
    text-decoration: none;
    color: black;
}
