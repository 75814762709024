.home-block a {
    text-decoration: none;
    color: black;
    background-color: #4CAF50;
    border: 2px solid black;
    border-radius: 4px;
    margin: 5px;
    padding: 5px
}

.home-block h1 {
    margin-top: 20px;
    margin-bottom: -5px
}

.home-block img {
    height: 250px;
    border-color: black;
    border-radius: 10px;
    border-style: solid;
    margin-top: 5px;
    margin-bottom: 10px
}
