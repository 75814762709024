.headerLinks {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #333;
    }

.headerLis {
float: left;
}

.headerLis a {
display: block;
color: white;
text-align: center;
padding: 14px 16px;
text-decoration: none;
}

.headerLis a:hover {
background-color: #111;
}
